import cx from 'classnames';
import { Text } from 'components/typography';
import { getRandomString } from 'lib/util';
import * as React from 'react';

type CheckboxProps = {
  className?: string;
  label?: React.ReactNode;
  id?: string;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  radioStyleTEMPORARY?: boolean;
  disabled?: boolean;
  required?: boolean;
  isDark?: boolean;
  defaultChecked?: boolean;
  /** Change size of checkbox input. Default: 'default', .75rem x .75rem */
  checkBoxSize?: 'default' | 'large';
  /** Add rounded corners to checkbox input. Default: 'none' */
  rounded?: 'none' | 'xs';
  /** Increases the width of the input label. Default: false, 'max-w-xs': 320px */
  isFullWidth?: boolean;
};

const Checkbox = ({
  className,
  label,
  id,
  name,
  onChange,
  disabled,
  radioStyleTEMPORARY,
  required,
  checked,
  checkBoxSize = 'default',
  rounded = 'none',
  isFullWidth = false,
  isDark = false,
  ...props
}: CheckboxProps) => {
  const wrapperClasses = cx(
    className,
    'my-3 flex items-start',
    isFullWidth ? 'w-full' : 'max-w-xs',
    {
      group: !disabled
    }
  );

  const labelClasses = cx(
    'pt-px', // 1px padding top is added to visually center align label text and check symbol
    { 'cursor-pointer': !disabled },
    { 'cursor-auto text-gray-500': disabled }
  );

  const regularClasses = cx('form-checkbox mr-2 cursor-pointer', {
    'mt-1 border-gray-500 transition-colors group-hover:border-gray-700':
      !isDark,
    'h-4 w-4 transition-colors checked:border-black checked:bg-black checked:text-white checked:hover:bg-black checked:focus:bg-black':
      isDark,
    'size-3': checkBoxSize === 'default',
    'size-4': checkBoxSize === 'large',
    'rounded-xs': rounded === 'xs'
  });

  const disabledClasses = 'disabled:border-gray-300 disabled:cursor-auto';

  const idRef = React.useRef(getRandomString());
  const inputId = id || idRef.current;

  // temporary until we get a radio component
  const radioStyleOverrides = {
    borderRadius: '50%'
  };

  return (
    <div className={wrapperClasses}>
      <input
        className={cx(regularClasses, disabledClasses)}
        type="checkbox"
        name={name}
        style={radioStyleTEMPORARY ? radioStyleOverrides : {}}
        id={inputId}
        aria-describedby={`checkbox-${inputId}`}
        aria-checked={checked}
        onChange={onChange}
        disabled={disabled}
        required={required}
        checked={checked}
        {...props}
      />

      {label && (
        <Text
          size="m"
          tagName="label"
          className={labelClasses}
          {...{ htmlFor: inputId, id: `checkbox-${inputId}` }}
        >
          {label}
        </Text>
      )}
    </div>
  );
};

export default Checkbox;
