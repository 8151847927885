import crush from '@tedconf/js-crushinator-helpers';
import cx from 'classnames';
import { Button, Icon, Text } from 'components/@tedui';
import Checkbox from 'components/checkbox';
import ContentContainer from 'components/content-container';
import { Honeypot } from 'components/footer/Honeypot';
import Image from 'components/image';
import { MASTER_LIST } from 'lib/hooks/useSailthru/const';
import { getOptinDate } from 'lib/hooks/useSailthru/utils';
import { isValidEmail } from 'lib/util';
import { useState } from 'react';

type WeeklyNewsletterType = {
  ted_ed_weekly_newsletter_subscriber: true;
  ted_ed_weekly_newsletter_frequency: 'weekly';
  ted_ed_weekly_newsletter_optin_date: unknown;
  ted_ed_weekly_newsletter_source: 'programs_ted-ed';
};

type DailyNewsletterType = {
  ted_ed_daily_newsletter_subscriber: true;
  ted_ed_daily_newsletter_frequency: 'daily';
  ted_ed_daily_newsletter_optin_date: unknown;
  ted_ed_daily_newsletter_source: 'programs_ted-ed';
};

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [newsletter, setNewsletter] = useState({ daily: true, weekly: false });
  const [honeypotChecked, setHoneypotChecked] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const onSubmit = () => {
    const isValid = isValidEmail(email);

    if (!isValid) {
      return;
    }

    if (newsletter.daily)
      signUp({
        ted_ed_daily_newsletter_subscriber: true,
        ted_ed_daily_newsletter_frequency: 'daily',
        ted_ed_daily_newsletter_optin_date: getOptinDate(),
        ted_ed_daily_newsletter_source: 'programs_ted-ed'
      });

    if (newsletter.weekly)
      signUp({
        ted_ed_weekly_newsletter_subscriber: true,
        ted_ed_weekly_newsletter_frequency: 'weekly',
        ted_ed_weekly_newsletter_optin_date: getOptinDate(),
        ted_ed_weekly_newsletter_source: 'programs_ted-ed'
      });
  };

  const signUp = (vars: DailyNewsletterType | WeeklyNewsletterType) => {
    if (honeypotChecked) return;

    if (window.Sailthru === undefined) {
      console.error('Sailthru is not defined');
      return;
    }

    window.Sailthru.integration('userSignUp', {
      email,
      lists: {
        ...MASTER_LIST
      },
      vars: vars as { [key: string]: string | number | boolean },
      source: 'web',
      onSuccess: () => {
        setEmail('');
        setNewsletter({ daily: false, weekly: false });
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
      },
      onError: () => {
        console.error('Error subscribing to newsletter');
      }
    });
  };

  const handleCheckboxChange = option =>
    setNewsletter(prev => ({
      ...prev,
      [option]: !prev[option]
    }));

  return (
    <ContentContainer className="bg-gray-300">
      <Honeypot onGrab={setHoneypotChecked} />
      <div className="mb-6 mt-5 h-px w-full bg-black" />
      <div className="bg-gray-100 flex flex-col justify-between gap-6 pb-20 md-tui:gap-10 lg-tui:flex-row lg-tui:gap-0">
        {showSuccess ? (
          <Text variant="header2" UNSAFE_className="flex-1" useNewTextStyles>
            Thanks for subscribing!
          </Text>
        ) : (
          <Text variant="header2" UNSAFE_className="flex-1" useNewTextStyles>
            Newsletter sign up
          </Text>
        )}
        <div className="flex flex-1 flex-col gap-10">
          <div className="flex gap-4">
            <div className="h-24 w-24 min-w-[92px]">
              <Image
                alt="TED-Ed"
                src={crush(
                  'https://ted-conferences-general-web-assets.s3.us-east-1.amazonaws.com/about-page/M.png',
                  { width: 92 }
                )}
                width={92}
                height={92}
              />
            </div>
            <div className="mr-4 flex flex-col">
              <Text variant="header4" useNewTextStyles UNSAFE_className="mb-2">
                TED-Ed
              </Text>
              <Text variant="body2" useNewTextStyles UNSAFE_className="mb-1">
                Daily animations for the constantly curious
              </Text>
              <div className="flex items-center gap-4">
                <Checkbox
                  checked={newsletter.daily}
                  label="Daily"
                  name="daily"
                  onChange={() => handleCheckboxChange('daily')}
                />
                <Checkbox
                  checked={newsletter.weekly}
                  label="Weekly"
                  name="weekly"
                  onChange={() => handleCheckboxChange('weekly')}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-4 md-tui:flex-row md-tui:items-start">
            <div className="flex w-full flex-col md-tui:w-[341px] lg-tui:w-full">
              <div className="relative w-full">
                <input
                  onChange={e => {
                    const isEmailValid = isValidEmail(e.target.value);
                    setIsValid(isEmailValid);
                    setEmail(e.target.value);
                  }}
                  placeholder="What's your email?"
                  id="newsletter-email"
                  className={cx(
                    'flex h-12 w-full flex-1 border-thin bg-white p-4 text-sm focus:outline-none',
                    {
                      'border-systemInfo-error-onLight focus:border-thin focus:border-systemInfo-error-onLight':
                        !isValid,
                      'border-systemInfo-success focus:border-systemInfo-success':
                        isValid && email.length > 0
                    }
                  )}
                  type="email"
                  value={email}
                />
                {!isValid && (
                  <Icon
                    iconName="alert-triangle"
                    className="absolute right-2 top-1/2 mr-2 -translate-y-1/2 transform text-tui-xl text-systemInfo-error-onDark"
                  />
                )}
              </div>

              {!isValid && (
                <Text
                  tag="p"
                  variant="caption"
                  UNSAFE_className="mt-1 ml-2"
                  color={{
                    color: 'error'
                  }}
                >
                  <span aria-live="polite">
                    Please add a valid email address
                  </span>
                </Text>
              )}
            </div>
            <Button
              variant="primary"
              text="Subscribe Now"
              UNSAFE_className="md-tui:min-w-35 min-w-full !h-12"
              isFullWidth={false}
              onClick={() => onSubmit()}
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}
