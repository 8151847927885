import { SliceZone } from '@prismicio/react';

import SignUp from 'components/pages/tedEd/SignUp';
import { components } from 'components/PRISMIC/slices';
import { Layout } from 'layout';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { createClient } from 'prismicio';

import type { JSX } from 'react';

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

type PageParams = { uid: string };

const LandingPage = ({ page }: PageProps): JSX.Element => {
  return (
    <Layout addMinHeight={false} isDarkMode>
      <Head>
        <title>{page.data.title || 'TED'}</title>
        {page.data.keywords && (
          <meta name="keywords" content={page.data.keywords} />
        )}
        {page.data.description && (
          <meta name="description" content={page.data.description} />
        )}
        {(page.data.thumbnail?.url || page.data.legacy_thumbnail_url) && (
          <meta
            property="og:image"
            content={
              page.data.thumbnail?.url || page.data.legacy_thumbnail_url!
            }
          />
        )}
        {page.data.custom_metadata?.map(
          item =>
            item &&
            item.key &&
            item.value && (
              <meta key={item.key} name={item.key} content={item.value} />
            )
        )}
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <div className="relative w-full bg-white">
        {page.data.slices && (
          <SliceZone slices={page.data.slices} components={components} />
        )}
      </div>
      {page.data.show_sign_up && <SignUp />}
    </Layout>
  );
};

export default LandingPage;

export async function getStaticProps({
  previewData,
  params
}: GetStaticPropsContext<PageParams>) {
  const client = createClient({ previewData });

  const uid = params?.uid;

  if (!uid) {
    return {
      notFound: true
    };
  }

  try {
    const page = await client.getByUID('landingPage', uid);

    if (!page || !page.data) {
      return {
        notFound: true
      };
    }

    return {
      props: {
        page
      }
    };
  } catch (error) {
    console.error(`Error fetching landing page with UID ${uid}:`, error);
    return {
      notFound: true
    };
  }
}

export async function getStaticPaths() {
  const shouldBuildAllPages =
    process.env.BUILD_ALL_PAGES === 'true' &&
    process.env.APP_ENV === 'production';

  if (shouldBuildAllPages) {
    try {
      const client = createClient();
      const pages = await client.getAllByType('landingPage');

      const safePaths = pages
        .map(page => {
          if (!page || !page.uid) {
            return null;
          }
          return {
            params: { uid: page.uid }
          };
        })
        .filter(Boolean); // Filter out null values

      return {
        paths: safePaths,
        fallback: 'blocking'
      };
    } catch (error) {
      console.error('Error fetching landing pages for static paths:', error);
      // Return empty paths as a fallback
      return {
        paths: [],
        fallback: 'blocking'
      };
    }
  }

  return {
    // We are setting an empty array here because we are using fallback: "blocking" and ISR
    paths: [],
    // More on this:
    // https://nextjs.org/docs/pages/api-reference/functions/get-static-paths#fallback-blocking
    // https://vercel.com/guides/how-do-i-reduce-my-build-time-with-next-js-on-vercel
    // (faster builds, but slower initial page load)
    fallback: 'blocking'
  };
}
